import React, { useEffect, useState } from "react";
import { Button, Card, Container } from "react-bootstrap";
import Pusher from "pusher-js";
import { useParams } from "react-router-dom";
import { faFileDownload, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Echo from "laravel-echo";
import usePalette from "../../../../hooks/usePalette";
const PruningDownload = () => {
  const { file_id } = useParams();
  const [isReady, setIsReady] = useState(false);
  const palette = usePalette();

  window.Echo.private(`files-${file_id}`).listen(".job.completed", (e) => {
    console.log("From echo: ", e);
    setIsReady(true);
  });

  /**
   * This has three states
   *
   * 1. Uploading
   * 2. Processing
   * 3. Ready for download
   */
  const downloadFile = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}administration/download-file/${file_id}`
      );
      // console.log("File: ", await res.json());
      const fileUrl = `${process.env.REACT_APP_API_URL}administration/download-file/${file_id}`;
      // Replace with the actual URL of the file

      // Create an anchor element
      const link = document.createElement("a");
      link.href = fileUrl;

      // Set the download attribute and filename
      link.setAttribute("download", "filename.pdf");

      // Append the anchor element to the document body
      document.body.appendChild(link);

      // Trigger the click event to start the download
      link.click();

      // Clean up by removing the anchor element from the document
      document.body.removeChild(link);
    } catch (err) {
      console.log("Error ", err);
    }
  };
  if (isReady) {
    return (
      <>
        <Container fluid className="p-0">
          <Card>
            <Card.Body>
              <div className="d-flex flex-column justify-content-center">
                <FontAwesomeIcon
                  className="mx-auto mb-3"
                  icon={faDownload}
                  size="5x"
                  color={palette.primary}
                />
                <h3 className="text-center mb-5">
                  Your file is ready for download!
                </h3>
                <Button
                  onClick={downloadFile}
                  variant="primary"
                  className="px-5 py-3"
                >
                  <FontAwesomeIcon icon={faFileDownload} className="me-2" />{" "}
                  Download File
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Container>
      </>
    );
  }
  return (
    <>
      <Container fluid className="p-0">
        <Card>
          <Card.Body>
            <h1 className="text-center mb-3">Processing your file</h1>
            <div className="d-flex flex-row justify-content-center">
              <span className="m-5 processing-loader"></span>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
export default PruningDownload;

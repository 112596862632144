import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const campaignApi = createApi({
  reducerPath: "campaignApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = window.localStorage.getItem("accessToken");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    mode: "cors",
    credentials: "omit",
  }),
  tagTypes: ["Campaigns", "DeliveryStatus"],
  endpoints: (builder) => ({
    checkDeliveryReport: builder.mutation({
      query: (fd) => ({
        url: "/campaign_delivery_report",
        method: "POST",
        body: fd,
      }),
      invalidatesTags: ["DeliveryStatus"],
    }),
  }),
});

export const { useCheckDeliveryReportMutation } = campaignApi;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createSlice } from "@reduxjs/toolkit";
export const accountsApi = createApi({
  reducerPath: "accountsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = window.localStorage.getItem("accessToken");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    mode: "cors",
    credentials: "omit",
  }),
  tagTypes: [
    "Accounts",
    "PeriodicSMS",
    "Users",
    "CampaignReportEmails",
    "CampaignReportTimes",
    "ApiKeys",
    "AccountSettings",
  ],
  endpoints: (builder) => ({
    getAllAccounts: builder.query({
      query: () => "administration/accounts",
      transformErrorResponse: (response, meta, arg) => response.status,
      providesTags: ["Accounts", "ApiKeys"],
    }),
    addNewCustomer: builder.mutation({
      query: (values) => {
        return {
          url: "register_new_client ",
          method: "POST",
          body: values,
        };
      },
      transformErrorResponse: (response, meta, arg) => response.data,
      invalidatesTags: ["Accounts"],
    }),
    getSmsSettings: builder.query({
      query: (id) => ({ url: `account/settings/sms/${id}` }),
      transformErrorResponse: (response, meta, arg) => response.status,
      providesTags: ["AccountSettings"],
    }),
    updateSmsSettings: builder.mutation({
      query: (values) => {
        return {
          url: "account/settings/update_sms",
          method: "POST",
          body: values,
        };
      },
      invalidatesTags: ["AccountSettings"],
    }),
    getAllApiKeys: builder.query({
      query: () => "fetch_account_api_keys",
      transformErrorResponse: (response, meta, arg) => response.status,
      providesTags: ["ApiKeys"],
    }),
    getAllPeriodicSMS: builder.query({
      query: () => "anniversary-sms",
      transformErrorResponse: (response, meta, args) => response.status,
      providesTags: ["PeriodicSMS"],
    }),
    getAccountUsers: builder.query({
      query: () => "account_users",
      transformErrorResponse: (response, meta, arg) => response.status,
      providesTags: ["Users"],
    }),
    addAccountUser: builder.mutation({
      query: (values) => {
        return {
          url: "create_user",
          method: "POST",
          body: values,
        };
      },
      invalidatesTags: ["Users"],
    }),
    deleteAccountUser: builder.mutation({
      query: (values) => {
        return {
          url: "delete_account_user",
          method: "POST",
          body: { id: values },
        };
      },
      invalidatesTags: ["Users"],
    }),
    addPeriodicSMS: builder.mutation({
      query: (values) => {
        return {
          url: "anniversary-sms",
          method: "POST",
          body: values,
        };
      },
      invalidatesTags: ["PeriodicSMS"],
    }),
    deletePeriodicSMS: builder.mutation({
      query: (values) => {
        return {
          url: "anniversary-sms",
          method: "DELETE",
          body: values,
        };
      },
    }),
    getCampaignReportSettings: builder.query({
      query: () => "campaign-settings-report",
      transformErrorResponse: (response, meta, arg) => response.status,
      providesTags: ["CampaignReportEmails", "CampaignReportTimes"],
    }),
    updateCampaignReportEmails: builder.mutation({
      query: (values) => {
        return {
          url: "campaign-update-report-emails",
          method: "POST",
          body: values,
        };
      },
      invalidatesTags: ["CampaignReportEmails"],
      transformErrorResponse: (response, meta, args) => response.status,
    }),
    updateCampaignReportTime: builder.mutation({
      query: (values) => {
        return {
          url: "campaign-update-report-time",
          method: "POST",
          body: values,
        };
      },
      invalidatesTags: ["CampaignReportTimes"],
      transformErrorResponse: (response, meta, args) => response.status,
    }),
    updateWeeklyCampaignReportSettings: builder.mutation({
      query: (values) => {
        return {
          url: "campaign-weekly-report-settings",
          method: "POST",
          body: values,
        };
      },
      invalidatesTags: ["CampaignReportTimes"],
      transformErrorResponse: (response, meta, args) => response.status,
    }),
  }),
});

const accountSlice = createSlice({
  name: "account",
  initialState: {
    api_keys: [],
  },
  reducers: {
    setApiKeys: (state, action) => {
      state.api_keys = action.payload;
    },
  },
});
export default accountSlice.reducer;
export function fetchApiKeys() {
  console.log("Fetching api keys slice");
  return async (dispatch) => {
    try {
      const response = await fetch(
        "https://apis.sema.co.tz/api/fetch_account_api_keys",
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "accessToken"
            )}`,
          },
        }
      );
      dispatch(accountSlice.actions.setApiKeys(response.data));
      console.log("FETCH API KEYS: ", response);
    } catch (err) {}
  };
}
export const {
  useGetAllAccountsQuery,
  useAddNewCustomerMutation,
  useGetAccountUsersQuery,
  useAddAccountUserMutation,
  useAddPeriodicSMSMutation,
  useGetAllApiKeysQuery,
  useGetAllPeriodicSMSQuery,
  useDeletePeriodicSMSMutation,
  useUpdateCampaignReportEmailsMutation,
  useUpdateCampaignReportTimeMutation,
  useGetCampaignReportSettingsQuery,
  useGetSmsSettingsQuery,
  useUpdateWeeklyCampaignReportSettingsMutation,
  useUpdateSmsSettingsMutation,
  useDeleteAccountUserMutation,
} = accountsApi;

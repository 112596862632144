import React from "react";
import { Row, Table } from "react-bootstrap";

// import { Edit2, Trash } from "react-feather";
// import inbox from "./inbox";

// const dlrReportTable = () => (
//   <Row>
//     <h1>DLR Report</h1>
//     <h6 className="text-muted">List of All SMS DLR Report</h6>
//     <Table responsive>
//       <thead>
//         <tr>
//           <th scope="col">SMS ID</th>
//           <th scope="col">Sender ID</th>
//           <th scope="col">DLR Status</th>
//           <th scope="col">Message</th>
//           <th scope="col">Phone Number</th>
//           <th scope="col">MCC</th>
//           <th scope="col">MNC</th>
//           <th scope="col">Type</th>
//           <th scope="col">Length</th>
//           <th scope="col">Characters Added</th>
//           <th scope="col">Rate</th>
//           <th scope="col">Cost</th>
//           <th scope="col">Submit Date</th>
//           <th scope="col">Sent Date</th>
//           <th scope="col">DLR Date</th>
//           <th scope="col">Parts</th>
//           <th scope="col">Country</th>
//           <th scope="col">Operator</th>
//           <th scope="col">Job ID</th>
//           <th scope="col">Source</th>
//           <th scope="col">Error Code</th>
//         </tr>
//       </thead>
//       <tbody>
//         <tr>
//           <th scope="row">1</th>
//           <td>10000</td>
//           <td>TZS</td>
//           <td>24/01/2021</td>
//           <td>some text</td>
//           <td></td>
//           <td>NO</td>
//           <td>27/09/2021</td>
//           <td></td>
//         </tr>
//         <tr>
//           <th scope="row">2</th>
//           <td>100000</td>
//           <td>TZS</td>
//           <td>12/09/2021</td>
//           <td>some text</td>
//           <td></td>
//           <td>YES</td>
//           <td>27/09/2021</td>
//           <td>For testing</td>
//         </tr>
//         <tr>
//           <th scope="row">3</th>
//           <td>50000</td>
//           <td>TZS</td>
//           <td></td>
//           <td>some other text</td>
//           <td>March, 23rd 2020</td>
//           <td>YES</td>
//           <td>27/09/2021</td>
//           <td>For Testing</td>
//         </tr>
//       </tbody>
//     </Table>
//   </Row>
// );

const dlrReportTable = ({ data }) => {
  const { JobName, TotalSms, JobDateTime, DlrStatus } = data;
  const { Delivered, Undeliverable, Failed, Accepted } = DlrStatus;
  console.log("Jobname: ", JobName);
  return (
    <>
      <h3 className="bg-primary text-light py-2">{JobName}</h3>
      <Table striped responsive hover>
        <thead>
          <tr className="bg-dark">
            <th>Name of Campaign</th>
            <th>SMS Sent</th>
            <th>Date</th>
            <th></th>
          </tr>
          <tr>
            <th>{JobName}</th>
            <th>{TotalSms}</th>
            <th>{JobDateTime}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr></tr>
          <tr>
            <th>Status</th>
            <th>SMS</th>
            <th>Percentage %</th>
            <th>Amount (TZS)</th>
          </tr>
          <tr>
            <td>Delivered</td>
            <td>{Delivered}</td>
            <td>{((Delivered / TotalSms) * 100).toFixed(2)}</td>
            <td>{8 * Delivered}</td>
          </tr>
          <tr>
            <td>Undeliverable</td>
            <td>{Undeliverable}</td>
            <td>{((Undeliverable / TotalSms) * 100).toFixed(2)}</td>
            <td>{8 * Undeliverable}</td>
          </tr>
          <tr>
            <td>Failed</td>
            <td>{Failed}</td>
            <td>{((Failed / TotalSms) * 100).toFixed(2)}</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Other</td>
            <td>{TotalSms - (Delivered + Undeliverable + Failed)}</td>
            <td>
              {(
                ((TotalSms - (Delivered + Undeliverable + Failed)) / TotalSms) *
                100
              ).toFixed(2)}
            </td>
            <td>{8 * Accepted}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>Total</td>
            <td>{(Delivered + Undeliverable + Accepted) * 8}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default dlrReportTable;

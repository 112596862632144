import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import NotyfContext from "../../contexts/NotyfContext";
import { useContext } from "react";

function ResetPasswordForm() {
  const notyf = useContext(NotyfContext);
  const navigate = useNavigate();
  const { token } = useParams();

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        password_confirmation: "",
        token: token,
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string()
          .required("Password is required")
          .min(8, "Password must be at least 8 characters long"),
        password_confirmation: Yup.string().oneOf(
          [Yup.ref("password"), null],
          "Passwords don't match"
        ),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        console.log("Form password reset with new password: ", values);
        const fd = new FormData();
        fd.append("email", values.email);
        fd.append("password", values.password);
        fd.append("password_confirmation", values.password_confirmation);
        fd.append("token", values.token);

        try {
          const response = await fetch(
            "https://apis.sema.co.tz/api/reset-password-handle-form",
            {
              body: fd,
              method: "POST",
              mode: "cors",
              credentials: "include",
            }
          );
          const res = await response.json();
          console.log("PASSWORD RESET FROM HANDLE SUMBIT: ", res);
          notyf.success(res.msg);
          navigate("/auth/sign-in");
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert className="my-3" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              label="Email Address"
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.email && (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              label="New Password"
              value={values.password}
              isInvalid={Boolean(touched.password && errors.password)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.password && (
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              name="password_confirmation"
              label="Confirm Password"
              value={values.password_confirmation}
              isInvalid={Boolean(
                touched.password_confirmation && errors.password_confirmation
              )}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.password_confirmation && (
              <Form.Control.Feedback type="invalid">
                {errors.password_confirmation}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <div className="text-center mt-3">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              Reset password
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ResetPasswordForm;

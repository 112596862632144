import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  Row,
  Container,
  Badge,
  Button,
  Modal,
  Form,
  Alert,
  Spinner,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Code } from "react-feather";
import axios from "axios";
import ModalForm from "../../ui/ModalForm/modalForm";
import NotyfContext from "../../../contexts/NotyfContext";
import { Formik } from "formik";
import * as Yup from "yup";

const AccountApi = () => {
  const notyf = useContext(NotyfContext);
  const [apiId, setApiId] = useState("");

  useEffect(() => {
    async function fetchApiId() {
      try {
        const res = await axios.get("get_api_keys", {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "accessToken"
            )}`,
          },
          withCredentials: false,
        });
        console.log("api response: ", res);
        if (res.status === 200) {
          setApiId(res.data["api_id"]);
        }
      } catch (err) {
        console.log("Fetching error: ", err);
      }
    }
    fetchApiId();
  }, []);

  //modal manipulation
  const [modalState, setModalState] = useState(false);

  let form = modalState ? (
    <Modal show={modalState}>
      <Modal.Header>
        <Modal.Title>Update Api Keys</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{ api_id: apiId, api_password: "" }}
          validationSchema={Yup.object().shape({
            api_id: Yup.string().required("Api Id is required"),
            api_password: Yup.string().required("Api password is required"),
          })}
          onSubmit={async (values, { setStatus, setErrors, setSubmitting }) => {
            try {
              console.log("api keys values: ", values);
              setSubmitting(true);
              const res = await axios.post(
                `${process.env.REACT_APP_API_URL}update_api_keys`,
                null,
                {
                  headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                      "accessToken"
                    )}`,
                  },
                  params: values,
                  withCredentials: false,
                }
              );

              console.log("update api keys: ", res);
            } catch (err) {
              console.log("update api keys error: ", err);
              setSubmitting(false);
              setStatus({ success: true });
              setErrors("Something went wrong");
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Form onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert className="my-3" variant="danger">
                  <div className="alert-message">{errors.submit}</div>
                </Alert>
              )}
              <Form.Group className="mb-3">
                <Form.Label>API ID</Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  name="api_id"
                  placeholder="API..."
                  value={values.api_id}
                  isInvalid={Boolean(touched.api_id && errors.api_id)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.api_id && (
                  <Form.Control.Feedback type="invalid">
                    {errors.api_id}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>API Password</Form.Label>
                <Form.Control
                  size="lg"
                  type="password"
                  name="api_password"
                  value={values.api_password}
                  isInvalid={Boolean(
                    touched.api_password && errors.api_password
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.api_password && (
                  <Form.Control.Feedback type="invalid">
                    {errors.api_password}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <div className="d-flex flex-row justify-content-between">
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : null}
                  Save
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={() => setModalState(false)}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  ) : null;

  return (
    <React.Fragment>
      {form}
      <Helmet title="New Accounts" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Account API</h1>
        <Row>
          <Card>
            <Card.Header tag="h5">
              Edit API keys<br></br>
            </Card.Header>
            <Card.Body className="m-sm-4">
              <div className="text-center">
                <Code size={32} />
                <br />
                <h2>
                  <Badge bg="secondary">{apiId}</Badge>
                </h2>
                <br />
                <br />
                <Button
                  onClick={() => setModalState(true)}
                  variant="outline-primary"
                >
                  Change API Password
                </Button>
              </div>
            </Card.Body>
          </Card>

          <Card>
            <Card.Header>API Documentation</Card.Header>
            <Card.Body>
              <div className="text-center">
                <h3>Download the SMS API Documentation</h3>
                <br></br>
                <br></br>
                <Button
                  onClick={() => {
                    fetch(`${process.env.REACT_APP_API_URL}download_doc`, {
                      headers: {
                        Authorization: `Bearer ${window.localStorage.getItem(
                          "accessToken"
                        )}`,
                      },
                    })
                      .then((res) =>
                        notyf.success("Download should start soon")
                      )
                      .catch((err) =>
                        notyf.error("Error fetching documentation")
                      );
                  }}
                  variant="outline-primary"
                >
                  Download Documentation
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default AccountApi;

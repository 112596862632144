import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import { Alert } from "react-bootstrap";
import * as Yup from "yup";
import NotyfContext from "../../../../../contexts/NotyfContext";
// import InputMask from "react-input-mask";

import { Card, Container, Row, Button, Form, Col } from "react-bootstrap";
import DlrReportTable from "./dlrReportTable";

const SmsDlrReport = () => {
  const notyf = useContext(NotyfContext);
  const [report, setReport] = useState([]);
  const [jobReport, setJobReport] = useState([]);
  let i = 0;
  return (
    <React.Fragment>
      <Helmet title="SMS DLR Report" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">DLR REPORT</h1>
        <Card>
          <Card.Header>
            <Card.Title tag="h5">CAMPAIGN REPORTS</Card.Title>
            <h6 className="card-subtitle mb-3 text-muted">
              All Campaign Delivery Reports
            </h6>
            <Button variant="success">EXPORT TO EXCEL</Button>
          </Card.Header>
          <Card.Body>
            <Row>
              <Formik
                initialValues={{
                  date: "",
                  job_id: "",
                  sender_id: "",
                  delivery_status: "",
                  date: "",
                }}
                validationSchema={Yup.object().shape({
                  date: Yup.date("Must be a valid date"),
                  job_id: Yup.number("Must be a number").notRequired(),
                  sender_id: Yup.string().notRequired(),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  console.log("DELIVERY STATUS VALUES: ", values);
                  const fd = new FormData();
                  fd.append("job_id", values.job_id);
                  fd.append("delivery_status", values.delivery_status);
                  fd.append("date", values.date);
                  const res = await fetch(
                    `${process.env.REACT_APP_API_URL}campaign_delivery_report`,
                    {
                      method: "POST",
                      mode: "cors",
                      credentials: "omit",
                      body: fd,
                      headers: {
                        ContentType: "application/json",
                        Authorization: `Bearer ${window.localStorage.getItem(
                          "accessToken"
                        )}`,
                      },
                    }
                  );
                  const body = await res.json();
                  console.log("DLR REPORT BODY: ", body);
                  switch (body.ErrorCode) {
                    case 1000:
                      notyf.success("Succesfully fetched delivery report");
                      setReport(body.CampaignDetail);
                      break;
                    case 1011:
                      notyf.error("Inavlid Delivery status");
                      break;
                    case 1010:
                      notyf.error("No Job Data Found");
                      break;
                    default:
                      notyf.error(body.Remarks);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    {errors.submit && (
                      <Alert className="my-3" variant="danger">
                        <div className="alert-message">{errors.submit}</div>
                      </Alert>
                    )}
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Date</Form.Label>
                          <Form.Control
                            size="lg"
                            type="date"
                            name="date"
                            value={values.date}
                            isInvalid={Boolean(touched.date && errors.date)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <span className="text-muted">e.g "MM/DD/YYYY"</span>
                          {!!touched.date && (
                            <Form.Control.Feedback type="invalid">
                              {errors.date}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Job ID</Form.Label>
                          <Form.Control
                            size="lg"
                            name="job_id"
                            value={values.job_id}
                            isInvalid={Boolean(touched.job_id && errors.job_id)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {!!touched.job_id && (
                            <Form.Control.Feedback type="invalid">
                              {errors.job_id}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Sender ID (optional)</Form.Label>
                          <Form.Control
                            size="lg"
                            name="sender_id"
                            value={values.sender_id}
                            isInvalid={Boolean(
                              touched.sender_id && errors.sender_id
                            )}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {!!touched.sender_id && (
                            <Form.Control.Feedback type="invalid">
                              {errors.sender_id}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>DLR Status</Form.Label>
                          <Form.Select
                            size="lg"
                            name="delivery_status"
                            value={values.delivery_status}
                            isInvalid={Boolean(
                              touched.delivery_status && errors.delivery_status
                            )}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            <option value="">View All</option>
                            <option value="pending">Pending</option>
                            <option value="delivered">Delivered</option>
                            <option value="undeliverable">Undeliverable</option>
                            <option>Acknowledged</option>
                            <option>Expired</option>
                            <option value="accepted">Accepted</option>
                            <option>Rejected</option>
                            <option value="failed">Failed</option>
                            <option>DND</option>
                            <option>None</option>
                            <option>Enrote</option>
                            <option>Deleted</option>
                            <option>Unknown</option>
                            <option>Submitted</option>
                          </Form.Select>
                          {!!touched.delivery_status && (
                            <Form.Control.Feedback type="invalid">
                              {errors.delivery_status}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <hr />
                    <div className="d-flex flex-row justify-content-end">
                      <div className="p-3">
                        <Button type="submit" variant="primary">
                          View DLR Report
                        </Button>
                      </div>
                      <div className="p-3">
                        <Button disabled variant="success">
                          Download Excel
                        </Button>
                      </div>
                      <div className="p-3">
                        <Button disabled variant="info">
                          Generate CSV
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Row>

            <Row>
              {report.map((rep) => {
                console.log("REp: ", rep);

                return <DlrReportTable key={i++ + "rep"} data={rep} />;
              })}
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};
export default SmsDlrReport;

import React from "react";
import { Card, Row, Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Eye } from "react-feather";
import NewAccountsTable from "./NewAccountsTable";
import { useGetAllAccountsQuery } from "../../../../../redux/slices/accounts";
import { Link } from "react-router-dom";
import FullTable from "../../../../ui/tables/FullTable";

const Accounts = () => {
  const navigate = useNavigate();
  const { data = [], isLoading, error } = useGetAllAccountsQuery();
  const columns = [
    {
      Header: "Company name",
      accessor: "company_name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Company email",
      accessor: "company_email",
    },
    {
      Header: "Phone number",
      accessor: "phone_number",
    },
    {
      Header: "Created at",
      accessor: "created_at",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value, row }) => {
        if (value === "Activated") {
          return <strong className="text-success">Activated</strong>;
        } else if (value === "Trial") {
          return <span>Trial</span>;
        } else if (value === "Requests Activation") {
          return (
            <Link
              to={`/admin/manage_accounts/activate/${row.original.id}`}
              className="text-warning"
            >
              Requests Activation
            </Link>
          );
        } else {
          return <span className="text-danger">Unknown</span>;
        }
      },
    },
    {
      Header: "Actions",
      accessor: "id",
      Cell: ({ value }) => {
        return (
          <div className="d-flex justify-content-center">
            <Eye
              style={{ cursor: "pointer" }}
              size={16}
              onClick={() => navigate(`${value}`)}
            />
          </div>
        );
      },
    },
  ];
  return (
    <React.Fragment>
      <Helmet title="All Accounts" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">All Accounts</h1>
        <Row>
          <Card>
            <Card.Header tag="h5">
              A list of all accounts<br></br>
            </Card.Header>
            <Card.Body className="m-sm-4">
              <FullTable values={data} cols={columns} />
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Accounts;

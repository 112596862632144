import React from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";

import Header from "./Header";
// import Appointments from "./Appointments";
import BarChart from "./BarChart";
// import Calendar from "./Calendar";
// import Feed from "./Feed";
// import PieChart from "./PieChart";
import Projects from "./Projects";
import Statistics from "./Statistics";
// import StripedRows from '../../tables/Tables';
import LastTransactions from "./LastTransactions";
import DashboardLayout from "../../../layouts/Dashboard";
import { useSelector } from "react-redux";
const Default = () => {
  const currentService = useSelector((state) => state.sema_services.value);
  return (
    // <DashboardLayout>
    <React.Fragment>
      <Helmet title={currentService.name} />
      <Container fluid className="p-0">
        <Header />
        <Statistics />
        <Row>
          <Col lg="8" className="d-flex">
            <BarChart />
          </Col>
          <Col lg="4" className="d-flex">
            <LastTransactions />
            {/* <Feed /> */}
          </Col>
        </Row>
        {/* <Row>
        <Col lg="6" xl="4" className="d-flex">
          <Calendar />
        </Col>
        <Col lg="6" xl="4" className="d-flex">
          <PieChart />
        </Col>
        <Col lg="6" xl="4" className="d-flex">
          <Appointments />
        </Col>
      </Row> */}
        <Projects />
      </Container>
    </React.Fragment>
    // </DashboardLayout>
  );
};

export default Default;

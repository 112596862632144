import {
  ArrowRightCircle,
  Code,
  Globe,
  Info,
  List,
  Mail,
  Shield,
  Users,
} from "react-feather";

const gettingStartedSection = [
  {
    href: "#",
    icon: Info,
    title: "Dashboard",
  },
  {
    href: "#",
    icon: Code,
    title: "Send Airtime",
  },
  {
    href: "#",
    icon: Code,
    title: "Purchases",
  },
  {
    href: "#",
    icon: Code,
    title: "Coverage",
  },
  {
    href: "#",
    icon: Code,
    title: "Reports",
  },
  {
    href: "#",
    icon: Code,
    title: "API Setup",
  },
];

const navItems = [
  {
    title: "Getting Started",
    pages: gettingStartedSection,
  },
];

export default navItems;

import React, { useState, useCallback } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Form,
  Modal,
  InputGroup,
  Alert,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";
import Results from "./Results";

const TestNumbers = () => {
  return (
    <Modal show={true}>
      <Modal.Header>
        <Modal.Title>Test numbers</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{ phoneNumbers: [] }}
          onSubmit={async (values, { setStatus, setSubmitting, setErrors }) => {
            try {
            } catch (err) {}
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Form onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert className="my-3" variant="danger">
                  <div className="alert-message">{errors.submit}</div>
                </Alert>
              )}
              <FieldArray
                name="campaignReportEmails"
                render={(arrayHelpers) => (
                  <div>
                    {values.campaignReportEmails &&
                    values.campaignReportEmails.length > 0 ? (
                      values.campaignReportEmails.map((email, index) => (
                        <div key={index}>
                          <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>

                            <InputGroup>
                              <Form.Control
                                size="lg"
                                type="email"
                                name={`campaignReportEmails.${index}`}
                                placeholder="Enter your email"
                                value={values.campaignReportEmails[index]}
                                isInvalid={Boolean(
                                  touched.campaignReportEmails &&
                                    errors.campaignReportEmails
                                )}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="removeTooltip">Remove</Tooltip>
                                }
                              >
                                <Button
                                  variant="danger"
                                  size="lg"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  -
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltipadd">
                                    Add email above
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="success"
                                  size="lg"
                                  onClick={() => arrayHelpers.insert(index, "")}
                                >
                                  +
                                </Button>
                              </OverlayTrigger>
                            </InputGroup>

                            {!!touched.campaignReportEmails && (
                              <Form.Control.Feedback type="invalid">
                                {errors.campaignReportEmails}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </div>
                      ))
                    ) : (
                      <div className="d-flex align-self-center">
                        <Button
                          className="mb-3"
                          variant="outline-secondary"
                          onClick={() => arrayHelpers.push("")}
                        >
                          Add Email
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              />
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

const TestSenderID = () => {
  const [modalState, setModalState] = useState({
    modalOpen: false,
    excelColumns: [""],
    fileData: null,
  });
  const closeModal = () => {
    setModalState({ modalOpen: false });
  };
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = (e) => {
        // Do whatever you want with the file contents
        const wb = XLSX.read(reader.result, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        console.log("File: ", data);
        const allKeys = Object.keys(data[0]);
        //update the state
        const newState = modalState;
        console.log("Current state: modal should be true", newState);
        newState.excelColumns = allKeys;
        newState.modalOpen = true;
        newState.fileData = file;
        setModalState({ ...newState });
        console.log("Xlsx columns: ", allKeys);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".xlsx",
    maxFiles: 1,
  });
  return (
    <React.Fragment>
      <Helmet title="Test Sender IDs" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Sender ID Testing</h1>
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Header>
                <Card.Title tag="h5">Test sender id's in a file</Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Formik
                    initialValues={{
                      the_file: null,
                      sender_id_column: "",
                      test_phone_number: "",
                    }}
                    validationSchema={Yup.object().shape({
                      sender_id_column: Yup.string().required(
                        "Specify column containing sender ids"
                      ),
                      test_phone_number: Yup.string(),
                    })}
                    onSubmit={async (
                      values,
                      { setErrors, setStatus, setSubmitting }
                    ) => {
                      try {
                        console.log("SID values: ", values);
                        console.log("File: ", modalState.fileData);

                        const fd = new FormData();
                        fd.append(
                          "test_phone_number",
                          values.test_phone_number
                        );
                        fd.append("the_file", modalState.fileData);
                        fd.append("sender_id_column", values.sender_id_column);

                        const response = await fetch(
                          "http://127.0.0.1:8000/api/administration/test-sid",
                          {
                            method: "POST",
                            body: fd,
                          }
                        );
                        console.log("Test SID response: ", response);
                      } catch (err) {
                        console.log("Test SID Error: ", err);
                      }
                    }}
                  >
                    {}
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      touched,
                      values,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        {errors.submit && (
                          <Alert className="my-3" variant="danger">
                            <div className="alert-message">{errors.submit}</div>
                          </Alert>
                        )}
                        <Row>
                          <Col md={5}>
                            <Form.Group className="mb-3">
                              <Form.Label>File</Form.Label>
                              <div
                                {...getRootProps()}
                                className="bg-light p-1 pt-3 "
                              >
                                <input {...getInputProps()} />
                                {modalState.fileData == null ? (
                                  <p className="text-center">
                                    Drag 'n' drop some files here, or click to
                                    select files
                                  </p>
                                ) : (
                                  <p className="text-center">
                                    {modalState.fileData.name}
                                  </p>
                                )}
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-3">
                              <Form.Label>Phonenumber for testing</Form.Label>
                              <Form.Control
                                size="lg"
                                type="text"
                                name="test_phone_number"
                                placeholder="255xxxxx"
                                value={values.test_phone_number}
                                isInvalid={Boolean(
                                  touched.test_phone_number &&
                                    errors.test_phone_number
                                )}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                              {!!touched.test_phone_number && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.test_phone_number}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-3">
                              <Form.Label>Select sender id column</Form.Label>
                              <Form.Select
                                size="lg"
                                type="text"
                                name="sender_id_column"
                                isInvalid={Boolean(
                                  touched.sender_id_column &&
                                    errors.sender_id_column
                                )}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              >
                                <option></option>
                                {modalState.excelColumns !== undefined
                                  ? modalState.excelColumns.map((col) => (
                                      <option key={col} value={col}>
                                        {col}
                                      </option>
                                    ))
                                  : null}
                              </Form.Select>
                              {!!touched.sender_id_column && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.sender_id_column}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </Col>
                          <Col md={1}>
                            <div className="text-center mt-3">
                              <Button
                                type="submit"
                                variant="primary"
                                size="lg"
                                disabled={isSubmitting}
                              >
                                Submit
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                  <Col></Col>
                </Row>
              </Card.Body>
            </Card>
            <Results />
          </Col>
          {/* <Col lg={5}>
              <Card>
                <Card.Body>
                  <Matrix />
                </Card.Body>
              </Card>
            </Col> */}
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default TestSenderID;

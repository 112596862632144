import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";

import reportWebVitals from "./utils/reportWebVitals";
import App from "./App";
import Pusher from "pusher-js";
import Echo from "laravel-echo";

import "./mocks";
import { Provider } from "react-redux";
import { store } from "./redux/store";
axios.interceptors.request.use((request) => {
  // axios.defaults.headers["access-control-allow-origin"] =
  //   "http://localhost:3000";
  // axios.defaults.withCredentials = true;

  return request;
});
window.Pusher = Pusher;

window.Echo = new Echo({
  broadcaster: "pusher",
  key: "2baf5990bd599b6d85c2",
  cluster: "eu",
  forceTLS: true,
  authEndpoint: "https://apis.sema.co.tz/broadcasting/auth",
  auth: {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`, // Replace accessToken with your actual bearer token
    },
  },
});
console.log("Echo: ", window.Echo);
ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

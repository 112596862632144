import React from "react";
import {
  Card,
  Row,
  Col,
  Container,
  ListGroup,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Placeholder,
  Spinner,
  Form,
  ToggleButton,
  Alert,
  Badge,
  Button,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { Helmet } from "react-helmet-async";
import {
  useGetAllAccountsQuery,
  useGetAccountUsersQuery,
  useGetSmsSettingsQuery,
  useUpdateSmsSettingsMutation,
} from "../../../../../redux/slices/accounts";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import moment from "moment";
import FullTable from "../../../../ui/tables/FullTable";
import { Edit, Trash } from "react-feather";

const Navigation = ({ id }) => {
  const { setting } = useParams();
  console.log("Notification current setting: ", setting);
  const navigate = useNavigate();
  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Account Settings
        </Card.Title>
      </Card.Header>
      <ListGroup variant="flush">
        <ListGroup.Item
          onClick={() =>
            navigate(`/admin/manage_accounts/accounts/${id}/profile`)
          }
          action
          active={setting === "profile" ? true : false}
        >
          Profile
        </ListGroup.Item>
        <ListGroup.Item
          onClick={() =>
            navigate(`/admin/manage_accounts/accounts/${id}/account-users`)
          }
          action
          active={setting === "account-users" ? true : false}
        >
          Users
        </ListGroup.Item>
        <ListGroup.Item tag="a" href="#" action>
          Privacy and safety
        </ListGroup.Item>
        <ListGroup.Item
          onClick={() =>
            navigate(
              `/admin/manage_accounts/accounts/${id}/email-notifications`
            )
          }
          action
          active={setting === "email-notifications" ? true : false}
        >
          Email Notifications
        </ListGroup.Item>
        <ListGroup.Item tag="a" href="#" action>
          Web notifications
        </ListGroup.Item>
        <ListGroup.Item tag="a" href="#" action>
          Widgets
        </ListGroup.Item>
        <ListGroup.Item tag="a" href="#" action>
          Your data
        </ListGroup.Item>
        <ListGroup.Item tag="a" href="#" action>
          Delete account{" "}
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
};

const AccountProfile = (acc) => {
  console.log("Accountprfile component: ", acc);
  const { account } = acc;
  return (
    <Formik
      initialValues={{ ...account, isEditable: "true" }}
      validationSchema={Yup.object().shape({})}
      onSubmit={async (values, { setErrors, setSubmitting, setStatus }) => {
        try {
          console.log("Account Profile: ", values);
          setSubmitting(true);
          setStatus({ success: true });
        } catch (err) {
          setErrors("An errror occured");
          setSubmitting(false);
          setStatus({ success: false });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert className="my-3" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}
          <Card>
            <Card.Header tag="h5">
              <div className="d-flex flex-row justify-content-between">
                <Card.Title>Account Profile</Card.Title>
                <Form.Group>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    label="Edit"
                    value="true"
                    name="isEditable"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Group>
              </div>
              <hr></hr>
            </Card.Header>
            <Card.Body className="m-sm-4">
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Company name</Form.Label>
                    <Form.Control
                      size="lg"
                      type="text"
                      name="company_name"
                      disabled={values.isEditable === "true" ? true : false}
                      placeholder=""
                      value={values.company_name}
                      isInvalid={Boolean(
                        touched.company_name && errors.company_name
                      )}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.company_name && (
                      <Form.Control.Feedback type="invalid">
                        {errors.company_name}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Status</Form.Label>
                    <h2>
                      <Badge className="p-2" bg="warning">
                        {account.status}
                      </Badge>
                    </h2>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Date of registration</Form.Label>
                    <h2>
                      <Badge bg="secondary">
                        {account.created_at
                          ? moment(account.created_at).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )
                          : "Not set"}
                      </Badge>
                    </h2>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Last updated</Form.Label>
                    <h2>
                      <Badge bg="secondary">
                        {moment(account.updated_at).fromNow() ?? "Not set"}
                      </Badge>
                    </h2>
                  </Form.Group>
                </Col>
              </Row>
              <section>
                Emails:
                <Row>
                  <Col md={6} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Company email</Form.Label>
                      <Form.Control
                        size="lg"
                        type="email"
                        name="company_email"
                        disabled={values.isEditable === "true" ? true : false}
                        placeholder=""
                        value={values.company_email}
                        isInvalid={Boolean(
                          touched.company_email && errors.company_email
                        )}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.company_email && (
                        <Form.Control.Feedback type="invalid">
                          {errors.company_email}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Support email</Form.Label>
                      <Form.Control
                        size="lg"
                        type="email"
                        name="support_email"
                        disabled={values.isEditable === "true" ? true : false}
                        placeholder=""
                        value={values.support_email}
                        isInvalid={Boolean(
                          touched.support_email && errors.support_email
                        )}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.support_email && (
                        <Form.Control.Feedback type="invalid">
                          {errors.support_email}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Billing email</Form.Label>
                      <Form.Control
                        size="lg"
                        type="email"
                        name="billing_email"
                        disabled={values.isEditable === "true" ? true : false}
                        placeholder=""
                        value={values.billing_email}
                        isInvalid={Boolean(
                          touched.billing_email && errors.billing_email
                        )}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.billing_email && (
                        <Form.Control.Feedback type="invalid">
                          {errors.billing_email}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </section>
            </Card.Body>
            <Card.Footer>
              <hr></hr>
              <div className="d-flex flex-row justify-content-end">
                <Button
                  disabled={values.isEditable === "true" ? true : false}
                  variant="primary"
                  size="lg"
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </Card.Footer>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

const AccountUsers = (acc) => {
  const { data = [], isLoading, error } = useGetAccountUsersQuery();
  const columns = [
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "First name",
      accessor: "first_name",
    },
    {
      Header: "Last name",
      accessor: "last_name",
    },
    {
      Header: "Username",
      accessor: "username",
    },

    {
      Header: "Phonenumber",
      accessor: "phone_number",
    },
    {
      Header: "Email verified",
      accessor: "email_verified_at",
      Cell: ({ value }) =>
        value === null ? (
          <Badge bg="secondary">not verified</Badge>
        ) : (
          <Badge bg="success">verfied</Badge>
        ),
    },
    {
      Header: "Actions",
      accessor: "id",
      Cell: ({ value }) => (
        <div className="d-flex flex-row justify-content-around">
          <Edit color="#3F80EA" size={16} style={{ cursor: "pointer" }} />
          <Trash color="#d34d45" size={16} style={{ cursor: "pointer" }} />
        </div>
      ),
    },
  ];
  return (
    <Card>
      <Card.Header>
        <div className="d-flex flex-row justify-content-between">
          <div>
            <Card.Title>Account Users</Card.Title>
            <Card.Subtitle>All users registered to this account</Card.Subtitle>
          </div>
          <Button variant="success">Create user</Button>
        </div>
      </Card.Header>
      <Card.Body>
        <FullTable values={data} cols={columns} />
      </Card.Body>
    </Card>
  );
};

const EmailNotifications = (acc) => {
  const { account } = acc;
  console.log("email notification account: ", account);
  const { data = [], isLoading, error } = useGetSmsSettingsQuery(account.id);
  const [updateSmsSettings, result] = useUpdateSmsSettingsMutation();
  if (!isLoading) {
    console.log("Account settings: ", data["campaign_reports"]);
    return (
      <Card>
        <Card.Header>
          <Card.Title>Email notifications</Card.Title>
          <Card.Subtitle>
            Select the kinds of notifications you can about your activities and
            other reports
          </Card.Subtitle>
          <hr></hr>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={4}>
              <h3>Email notifications</h3>
              <p>
                Get emails to find out what's going on when you're not online.
                You can turn these off
              </p>
            </Col>
            <Col>form</Col>
          </Row>
          <hr></hr>
          <Row>
            <Col md={4}>
              <h3>Campaign reports</h3>
              <p>
                Receive detailed reports of your campaigns daily, weekly or
                montly
              </p>
            </Col>
            <Col md={4}>
              <Formik
                initialValues={{ ...data["campaign_reports"], id: account.id }}
                onSubmit={async (
                  values,
                  { setErrors, setSubmitting, setStatus }
                ) => {
                  try {
                    console.log("Campaign report values: ", values);
                    const res = await updateSmsSettings(values);
                    console.log("Update results: ", res);
                    setSubmitting(true);
                    setStatus({ success: true });
                  } catch (err) {
                    setSubmitting(false);
                    setStatus({ success: false });
                    setErrors({ message: "Error occurred" });
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    {errors.submit && (
                      <Alert className="my-3" variant="danger">
                        <div className="alert-message">{errors.submit}</div>
                      </Alert>
                    )}
                    <Form.Group className="mb-3">
                      <div className="d-flex flex-row">
                        <Form.Check
                          type="switch"
                          name="isEnabled"
                          checked={values.isEnabled}
                          onChange={handleChange}
                          value={true}
                          onBlur={handleBlur}
                        />
                        <Form.Label>
                          <strong>Enable campaign reports</strong>
                          <br></br>
                          Allow camaigns reports to be sent to you
                        </Form.Label>
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Notification emails</Form.Label>
                      <FieldArray
                        name="campaignReportEmails"
                        render={(arrayHelpers) => (
                          <div>
                            {values.campaignReportEmails &&
                            values.campaignReportEmails.length > 0 ? (
                              values.campaignReportEmails.map(
                                (email, index) => (
                                  <div key={index}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>Email</Form.Label>

                                      <InputGroup>
                                        <Form.Control
                                          size="lg"
                                          type="email"
                                          name={`campaignReportEmails.${index}`}
                                          placeholder="Enter your email"
                                          value={
                                            values.campaignReportEmails[index]
                                          }
                                          isInvalid={Boolean(
                                            touched.campaignReportEmails &&
                                              errors.campaignReportEmails
                                          )}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                        />
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip id="removeTooltip">
                                              Remove
                                            </Tooltip>
                                          }
                                        >
                                          <Button
                                            variant="danger"
                                            size="lg"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          >
                                            -
                                          </Button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip id="tooltipadd">
                                              Add email above
                                            </Tooltip>
                                          }
                                        >
                                          <Button
                                            variant="success"
                                            size="lg"
                                            onClick={() =>
                                              arrayHelpers.insert(index, "")
                                            }
                                          >
                                            +
                                          </Button>
                                        </OverlayTrigger>
                                      </InputGroup>

                                      {!!touched.campaignReportEmails && (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.campaignReportEmails}
                                        </Form.Control.Feedback>
                                      )}
                                    </Form.Group>
                                  </div>
                                )
                              )
                            ) : (
                              <div className="d-flex align-self-center">
                                <Button
                                  className="mb-3"
                                  variant="outline-secondary"
                                  onClick={() => arrayHelpers.push("")}
                                >
                                  Add Email
                                </Button>
                              </div>
                            )}
                          </div>
                        )}
                      />
                    </Form.Group>
                    <hr></hr>
                    <Form.Group className="mb-3">
                      <div className="d-flex flex-row">
                        <Form.Check
                          type="switch"
                          name="sendDaily"
                          checked={values.sendDaily}
                          value={!values.sendDaily}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <Form.Label>
                          <strong>Enable Daily reports</strong>
                          <br></br>Receive campaign reports daily
                        </Form.Label>
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Time to send the email report</Form.Label>
                      <Form.Control
                        type="time"
                        name="daily_send_time"
                        value={values.daily_send_time}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Form.Group>
                    <hr></hr>
                    <Form.Group className="mb-3">
                      <div className="d-flex flex-row">
                        <Form.Check
                          type="switch"
                          name="sendWeekly"
                          value={!values.sendWeekly}
                          checked={values.sendWeekly}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <Form.Label>
                          <strong>Enable Weekly reports</strong>
                          <br></br>Receive campaign reports weekly
                        </Form.Label>
                      </div>
                    </Form.Group>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Time to send the email report</Form.Label>
                          <Form.Select
                            name="weekly_day_of_week"
                            onChange={handleChange}
                            defaultValue={values.weekly_day_of_week}
                            onBlur={handleBlur}
                          >
                            <option>Monday</option>
                            <option>Tuesday</option>
                            <option>Wednesday</option>
                            <option>Thursday</option>
                            <option>Friday</option>
                            <option>Saturday</option>
                            <option>Sunday</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Time</Form.Label>
                          <Form.Control
                            type="time"
                            name="weekly_send_time"
                            value={values.weekly_send_time}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group className="mb-3">
                      <div className="d-flex flex-row">
                        <Form.Check
                          type="switch"
                          name="sendMonthly"
                          value={!values.sendMonthly}
                          checked={values.sendMonthly}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <Form.Label>
                          <strong>Enable Monthly reports</strong>
                          <br></br>Receive campaign reports at the end of each
                          month
                        </Form.Label>
                      </div>
                    </Form.Group>
                    <div className="d-flex flex-row justify-content-end">
                      <Button type="submit" variant="primary">
                        Save
                      </Button>
                      <Button variant="outline-secondary">Cancel</Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
  return (
    <Card>
      {" "}
      <Card.Header>
        <Card.Title>Email notifications</Card.Title>
        <Card.Subtitle>
          Select the kinds of notifications you can about your activities and
          other reports
        </Card.Subtitle>
        <hr></hr>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={7} /> <Placeholder xs={4} />
            </Placeholder>
          </Col>
          <Col>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={7} /> <Placeholder xs={4} />{" "}
              <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
              <Placeholder xs={8} />
            </Placeholder>
          </Col>
        </Row>
        <Row>
          <Col>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={7} /> <Placeholder xs={4} />
            </Placeholder>
          </Col>
          <Col>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={7} /> <Placeholder xs={4} />{" "}
              <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
              <Placeholder xs={8} />
            </Placeholder>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
const SingleAccount = () => {
  let currentSettingUi = "";

  const { id } = useParams();
  const { setting } = useParams();
  const { account } = useGetAllAccountsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      account: data.find((acc) => acc.id == id),
    }),
  });
  switch (setting) {
    case "email-notifications":
      currentSettingUi = <EmailNotifications account={account} />;
      break;
    case "account-users":
      currentSettingUi = <AccountUsers account={account} />;
      break;
    default:
      currentSettingUi = <AccountProfile account={account} />;
      break;
  }
  console.log("Account: ", account);
  const { company_name } = account;
  return (
    <React.Fragment>
      <Helmet title="All Accounts" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">{company_name}</h1>
        <Row>
          <Col md={2}>
            <Navigation id={id} />
          </Col>
          <Col>{currentSettingUi}</Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default SingleAccount;

import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Card,
  Container,
  Row,
  Button,
  Modal,
  Form,
  Alert,
} from "react-bootstrap";
import SenderIDTable from "./senderidTable";
import ModalForm from "../../../../ui/ModalForm/modalForm";
import CountryList from "./countryList";
import axios from "axios";
import { initialValues, validationSchema } from "./FormikForm";

const SenderIDs = () => {
  const [modalState, setModalState] = useState(false);

  let form = (
    <Modal show={modalState}>
      <Modal.Header>
        <Modal.Title>Request a New Sender ID</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{ country: "", sender_id: "", remarks: "" }}
          validationSchema={Yup.object().shape({
            country: Yup.string().required("Country is required"),
            sender_id: Yup.string().required("Specify sender id name"),
            remarks: Yup.string().notRequired(),
          })}
          onSubmit={async (values, { setErrors, setSubmitting, setStatus }) => {
            try {
              setSubmitting(true);
              const res = axios.post(
                "https://api.sema.co.tz/api/RequestSenderId",
                null,
                {
                  headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                      "accessToken"
                    )}`,
                  },
                  params: values,
                  withCredentials: false,
                }
              );
              if (res.status === 200) {
                setStatus({ success: true });
                setModalState(false);
              }
            } catch (err) {
              setErrors("Something went wrong");
              setSubmitting(false);
              setStatus({ success: false });
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Form onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert className="my-3" variant="danger">
                  <div className="alert-message">{errors.submit}</div>
                </Alert>
              )}
              <Form.Group className="mb-3">
                <Form.Label>Country</Form.Label>
                <Form.Select
                  size="lg"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="country"
                  value={values.country}
                  isInvalid={Boolean(touched.country && errors.country)}
                >
                  <option></option>
                  {CountryList.map((country) => (
                    <option key={country}>{country}</option>
                  ))}
                </Form.Select>
                {!!touched.country && (
                  <Form.Control.Feedback type="invalid">
                    {errors.country}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Sender ID name</Form.Label>
                <Form.Control
                  name="sender_id"
                  size="lg"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.sender_id}
                  isInvalid={Boolean(touched.sender_id && errors.sender_id)}
                />
                {!!touched.sender_id && (
                  <Form.Control.Feedback type="invalid">
                    {errors.sender_id}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Remarks</Form.Label>
                <Form.Control
                  name="remarks"
                  size="lg"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.remarks}
                  isInvalid={Boolean(touched.remarks && errors.remarks)}
                />
                {!!touched.remarks && (
                  <Form.Control.Feedback type="invalid">
                    {errors.remarks}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <div className="d-flex flex-row justify-content-between">
                <Button
                  onClick={() => setModalState(false)}
                  variant="outline-secondary"
                >
                  Cancel
                </Button>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
  return (
    <React.Fragment>
      {form}
      <Helmet title="SMS Sender ID" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Sender ID Manager</h1>
        <Card>
          <Card.Header>
            <Card.Title tag="h5">Your Sender IDs</Card.Title>
            <h6 className="card-subtitle mb-3 text-muted">
              All sms Sender IDs
            </h6>
            <Button
              variant="success"
              onClick={() => setModalState(true)}
              size="lg"
            >
              Create A New Sender ID
            </Button>
          </Card.Header>
          <Card.Body>
            <Row>
              <SenderIDTable />
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default SenderIDs;

import React from "react";

import PerfectScrollbar from "react-perfect-scrollbar";
import { Button } from "react-bootstrap";
import useSidebar from "../../hooks/useSidebar";
import useAppDispatch from "../../hooks/useAppDispatch";
import SidebarFooter from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
// import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import { switchService } from "../../redux/slices/serviceSlice";
import logo from "../../assets/img/sema-icon.png";
import { useNavigate } from "react-router-dom";
import useAppSelector from "../../hooks/useAppSelector";
import useAuth from "../../hooks/useAuth";
// import logo from "../../../public/sema-icon.png";

const Sidebar = ({ items, showFooter = true }) => {
  const { user } = useAuth();
  const currentService = useAppSelector((state) => state.sema_services.value);
  const { isOpen } = useSidebar();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const switchTheService = (service_name) => {
    console.log("Switching service to : ", service_name);
    console.log("user sidebar: ", user.isSemaAdmin);
    dispatch(switchService(service_name));
    switch (service_name) {
      case "bulk-sms":
        if (user.isSemaAdmin === 1) {
          navigate("/admin/welcome");
        }
        navigate("/sms");
        break;
      case "airtime":
        navigate("/airtime");
        break;
      default:
        break;
    }
  };

  return (
    <nav className={`sidebar ${!isOpen ? "collapsed" : ""}`}>
      <div className="sidebar-content">
        <PerfectScrollbar>
          <a className="sidebar-brand" href="/">
            {/* <Logo /> <span className="align-middle me-3">Bulk SMS</span> */}
            <div className="d-flex justify-content-space-around">
              <img src={logo} alt="Logo" />
              <span className="align-right me-3">{currentService.name}</span>
            </div>
            {/* <img src={logo} alt="logo" /> */}
          </a>

          {/* Added for services switch */}
          <div>
            <li>
              <Button
                variant="link"
                className={`sidebar-link`}
                onClick={(e) => switchTheService("bulk-sms")}
              >
                <span className="align-middle">Bulk SMS</span>
              </Button>
            </li>
            <li>
              <Button
                variant="link"
                className={`sidebar-link`}
                onClick={(e) => switchTheService("airtime")}
              >
                <span className="align-middle">AIRTIME</span>
              </Button>
            </li>
          </div>
          {/* end of services switch */}

          <SidebarNav items={items} />
          {!!showFooter && <SidebarFooter />}
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;

import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// const initialState = {
//   sender_ids: [
//     { sender_id: "PETWASH", countr: "Tanzania" },
//     { sender_id: "LICKS", country: "Tanzania" },
//   ],
// };

const senderIDSlice = createSlice({
  name: "sender_ids",
  initialState: {
    values: [
      { sender_id: "PETWASH", countr: "Tanzania" },
      { sender_id: "LICKS", country: "Tanzania" },
    ],
  },
  reducers: {
    setSenderIDs: (state, action) => {
      state.values = action.payload;
    },
  },
});

export default senderIDSlice.reducer;

export function retrieveSenderIDs() {
  return async (dispatch) => {
    try {
      // const response = await axios.get(
      //   "https://api.sema.co.tz/api/GetSenderIDList?api_id=API236492285&api_password=ForDemoClient123"
      // );
      const response = await axios.get(
        "https://apis.sema.co.tz/api/fetchsenderids",
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "accessToken"
            )}`,
          },
        }
      );

      dispatch(senderIDSlice.actions.setSenderIDs(response.data));
      console.log("Getting IDs", response);
    } catch (err) {
      console.log("Sender id", err);
    }
  };
}

import { Formik, FieldArray } from "formik";
import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  ListGroup,
  Form,
  Alert,
  Button,
  Spinner,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Badge,
} from "react-bootstrap";
import NotyfContext from "../../../contexts/NotyfContext";
import * as Yup from "yup";
import {
  useUpdateCampaignReportEmailsMutation,
  useUpdateCampaignReportTimeMutation,
  useGetCampaignReportSettingsQuery,
  useUpdateWeeklyCampaignReportSettingsMutation,
} from "../../../redux/slices/accounts";
const AccountReport = () => {
  const notyf = useContext(NotyfContext);
  const [updateCampaignReportEmails] = useUpdateCampaignReportEmailsMutation();
  const [updateCampaignReportTime] = useUpdateCampaignReportTimeMutation();
  const [updateWeeklyCampaignReportSettings] =
    useUpdateWeeklyCampaignReportSettingsMutation();
  const {
    data = { campaignReportEmails: [], campaignReportTime: "" },
    isLoading,
    error,
  } = useGetCampaignReportSettingsQuery();

  if (!isLoading) {
    console.log("Campaign settings: ", data);
    return (
      <>
        <Row>
          <Col md={2}>
            <Card>
              <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  Campaign Settings
                </Card.Title>
              </Card.Header>
              <ListGroup variant="flush">
                <ListGroup.Item tag="a" href="#" action active>
                  Reports
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header>
                <div className="d-flex flex-row justify-content-between align-self-center">
                  <div>
                    <Card.Title tag="h5">Campaign Reports</Card.Title>
                    <p>Recieve campaign reports in your emails</p>
                  </div>
                  <h3>
                    {data.isEnabled ? (
                      <Badge bg={"success"}>Enabled</Badge>
                    ) : (
                      <Badge bg="secondary">Disabled</Badge>
                    )}
                  </h3>
                </div>
              </Card.Header>
              <Card.Body>
                <Formik
                  initialValues={{
                    emails: data["campaignReportEmails"],
                  }}
                  validationSchema={Yup.object().shape({
                    emails: Yup.array().min(
                      1,
                      "At least one email is required"
                    ),
                  })}
                  onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting }
                  ) => {
                    try {
                      const res = await updateCampaignReportEmails(values);
                      console.log("Update campaign report emails: ", res);
                      setStatus({ success: true });
                      notyf.success(res.data["msg"]);
                    } catch (err) {
                      setStatus({ success: false });
                      setErrors({
                        submit: "Error occured",
                      });
                      setSubmitting(false);
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  }) => (
                    <div>
                      {isLoading ? (
                        <h1>Loading</h1>
                      ) : (
                        <Form onSubmit={handleSubmit}>
                          {errors.submit && (
                            <Alert className="my-3" variant="danger">
                              <div className="alert-message">
                                {errors.submit}
                              </div>
                            </Alert>
                          )}
                          <Form.Label>Emails to recieve reports</Form.Label>
                          <FieldArray
                            name="emails"
                            render={(arrayHelpers) => (
                              <div>
                                {values.emails && values.emails.length > 0 ? (
                                  values.emails.map((email, index) => (
                                    <div key={index}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>Email</Form.Label>

                                        <InputGroup>
                                          <Form.Control
                                            size="lg"
                                            type="email"
                                            name={`emails.${index}`}
                                            placeholder="Enter your email"
                                            value={values.emails[index]}
                                            isInvalid={Boolean(
                                              touched.emails && errors.emails
                                            )}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                          />
                                          <OverlayTrigger
                                            overlay={
                                              <Tooltip id="removeTooltip">
                                                Remove
                                              </Tooltip>
                                            }
                                          >
                                            <Button
                                              variant="danger"
                                              size="lg"
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            >
                                              -
                                            </Button>
                                          </OverlayTrigger>
                                          <OverlayTrigger
                                            overlay={
                                              <Tooltip id="tooltipadd">
                                                Add email above
                                              </Tooltip>
                                            }
                                          >
                                            <Button
                                              variant="success"
                                              size="lg"
                                              onClick={() =>
                                                arrayHelpers.insert(index, "")
                                              }
                                            >
                                              +
                                            </Button>
                                          </OverlayTrigger>
                                        </InputGroup>

                                        {!!touched.emails && (
                                          <Form.Control.Feedback type="invalid">
                                            {errors.emails}
                                          </Form.Control.Feedback>
                                        )}
                                      </Form.Group>
                                    </div>
                                  ))
                                ) : (
                                  <div className="d-flex align-self-center">
                                    <Button
                                      className="mb-3"
                                      variant="outline-success"
                                      size="lg"
                                      onClick={() => arrayHelpers.push("")}
                                    >
                                      Add Emails
                                    </Button>
                                  </div>
                                )}

                                <div>
                                  {isLoading ? null : (
                                    <Button
                                      variant="primary"
                                      type="submit"
                                      disabled={isSubmitting}
                                    >
                                      {isSubmitting ? (
                                        <Spinner
                                          as="span"
                                          animation="grow"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                      ) : null}
                                      Save
                                    </Button>
                                  )}
                                </div>
                              </div>
                            )}
                          />
                        </Form>
                      )}
                    </div>
                  )}
                </Formik>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header>
                <Card.Title tag="h5">Daily Campaign Report Time</Card.Title>
                <Card.Subtitle>
                  Set the time you want to receive emails
                </Card.Subtitle>
              </Card.Header>
              <Card.Body>
                <Formik
                  initialValues={{ report_time: data["daily_send_time"] }}
                  validationSchema={Yup.object().shape({
                    report_time: Yup.string().required("Select a time"),
                  })}
                  onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting }
                  ) => {
                    try {
                      const res = await updateCampaignReportTime(values);
                      console.log("Update campaign report emails: ", res);
                      setStatus({ success: true });
                      notyf.success(res.data["msg"]);
                    } catch (err) {
                      setStatus({ success: false });
                      setErrors({
                        submit: "Error occured",
                      });
                      setSubmitting(false);
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      {errors.submit && (
                        <Alert className="my-3" variant="danger">
                          <div className="alert-message">{errors.submit}</div>
                        </Alert>
                      )}
                      <Form.Group className="mb-3">
                        <Form.Label>Report time</Form.Label>
                        <Form.Control
                          size="lg"
                          type="time"
                          name="report_time"
                          value={values.report_time}
                          isInvalid={Boolean(
                            touched.report_time && errors.report_time
                          )}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {!!touched.report_time && (
                          <Form.Control.Feedback type="invalid">
                            {errors.report_time}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                      <Button
                        type="submit"
                        varaint="primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : null}
                        Save
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header>
                <div className="d-flex flex-row justify-content-between">
                  <div>
                    <Card.Title>Weekly Campaign Report Time</Card.Title>
                    <Card.Subtitle>
                      Set the time and day of the week you want to recieve a
                      report for the whole week
                    </Card.Subtitle>
                  </div>
                  <h3>
                    {data.sendWeekly ? (
                      <Badge bg="success">Enabled</Badge>
                    ) : (
                      <Badge bg="secondary">Disabled</Badge>
                    )}
                  </h3>
                </div>
              </Card.Header>
              <Card.Body>
                <Formik
                  initialValues={{
                    weekly_day_of_week: data["weekly_day_of_week"],
                    weekly_send_time: data["weekly_send_time"],
                  }}
                  onSubmit={async (
                    values,
                    setErrors,
                    setSubmitting,
                    setStatus
                  ) => {
                    try {
                      const res = updateWeeklyCampaignReportSettings(values);
                      setSubmitting(true);
                      setStatus({ success: true });
                    } catch (err) {
                      setStatus({ success: false });
                      setSubmitting(false);
                      setErrors("Something went wrong");
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  }) => (
                    <div>
                      {isLoading ? (
                        <h1>Loading</h1>
                      ) : (
                        <Form onSubmit={handleSubmit}>
                          {errors.submit && (
                            <Alert className="my-3" variant="danger">
                              <div className="alert-message">
                                {errors.submit}
                              </div>
                            </Alert>
                          )}
                          <Row>
                            <Col md={4} sm={12}>
                              <Form.Group>
                                <Form.Label>Day of week</Form.Label>
                                <Form.Select
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  defaultValue={values.weekly_day_of_week}
                                  name="weekly_day_of_week"
                                >
                                  <option></option>
                                  <option>Monday</option>
                                  <option>Tuesday</option>
                                  <option>Wednesday</option>
                                  <option>Thursday</option>
                                  <option>Friday</option>
                                  <option>Saturday</option>
                                  <option>Sunday</option>
                                </Form.Select>
                                {!!touched.weekly_day_of_week && (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.weekly_day_of_week}
                                  </Form.Control.Feedback>
                                )}
                              </Form.Group>
                            </Col>
                            <Col md={4} sm={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>
                                  Time to recieve the report
                                </Form.Label>
                                <Form.Control
                                  type="time"
                                  size="lg"
                                  name="weekly_send_time"
                                  value={values.weekly_send_time}
                                  isInvalid={Boolean(touched.weekly_send_time)}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />
                                {!!touched.weekly_send_time && (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.weekly_send_time}
                                  </Form.Control.Feedback>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                          <Button
                            variant="primary"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? (
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : null}
                            Save
                          </Button>
                        </Form>
                      )}
                    </div>
                  )}
                </Formik>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header>
                <div className="d-flex flex-row justify-content-between">
                  <div>
                    <Card.Title>Monthyly campaign report</Card.Title>
                    <Card.Subtitle>
                      Recieve a report of all you campaigns at the end of the
                      month
                    </Card.Subtitle>
                  </div>
                  <h3>
                    {data.sendMonthly ? (
                      <Badge bg="success">Enabled</Badge>
                    ) : (
                      <Badge bg="secondary">Disabled</Badge>
                    )}
                  </h3>
                </div>
              </Card.Header>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
  return <h1>Loading</h1>;
};

export default AccountReport;

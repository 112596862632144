import React from "react";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, ListGroup, Row } from "react-bootstrap";

import PublicInfo from "./forms/PublicInfo";

const MyProfile = () => (
  <React.Fragment>
    <Helmet title="My Profile" />
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Profile</h1>

      <Row>
        <Col xl="10">
          <PublicInfo />
        </Col>
      </Row>
    </Container>
  </React.Fragment>
);

export default MyProfile;

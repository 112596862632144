import React, { useContext } from "react";
import { Form, Container, Card, Row, Col, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { useAddAccountUserMutation } from "../../../../redux/slices/accounts";
import avatar1 from "../../../../assets/img/avatars/avatar.jpg";
import axios from "axios";
import useAuth from "../../../../hooks/useAuth";
import NotyfContext from "../../../../contexts/NotyfContext";

const CreateUserForm = () => {
  const { user } = useAuth();
  const notyf = useContext(NotyfContext);
  const [addAccountUser] = useAddAccountUserMutation();

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          username: "",
          email: "",
          password: "",
          first_name: "",
          last_name: "",
          phone_number: "",
          send_user_notification: true,
          role: "Administrator",
        }}
        validationSchema={Yup.object().shape({
          username: Yup.string().required("Username is required"),
          email: Yup.string().required("Email is required"),
          first_name: Yup.string().required("First name is required"),
          last_name: Yup.string().required("Last name is required"),
          password: Yup.string().min(8, "Must be atleast 8 characters long"),
          phone_number: Yup.string(),
          role: Yup.string().required("User role must be specified"),
        })}
        enableReinitialize={true}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          console.log(values);
          try {
            const response = await addAccountUser(values);
            notyf.success("User Created! Check email for verification");
          } catch (e) {
            console.log("Create user", e);
            notyf.error("Error creating user! " + e);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
            <h4>User Details</h4>
            <Row>
              <Col md="8">
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="inputUsername">Username</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    name="username"
                    onBlur={handleBlur}
                    isInvalid={Boolean(touched.username && errors.username)}
                  />
                  {!!touched.username && (
                    <Form.Control.Feedback type="invalid">
                      {errors.username}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="inputUsername">Firstname</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    name="first_name"
                    onBlur={handleBlur}
                    isInvalid={Boolean(touched.first_name && errors.first_name)}
                  />
                  {!!touched.first_name && (
                    <Form.Control.Feedback type="invalid">
                      {errors.first_name}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="inputUsername">Lastname</Form.Label>
                  <Form.Control
                    type="text"
                    name="last_name"
                    isInvalid={Boolean(touched.last_name && errors.last_name)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {!!touched.last_name && (
                    <Form.Control.Feedback type="invalid">
                      {errors.last_name}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="inputUsername">Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    onChange={handleChange}
                    isInvalid={Boolean(touched.password && errors.password)}
                    onBlur={handleBlur}
                  />
                  {!!touched.password && (
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <br></br>

            <h4>Contact information</h4>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="inputUsername">Email</Form.Label>
                  <Form.Control
                    type="email"
                    onChange={handleChange}
                    name="email"
                    isInvalid={Boolean(touched.email && errors.email)}
                    placeholder="johndoe@company.com"
                  />
                  {!!touched.email && (
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="inputUsername">Phone number</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone_number"
                    onChange={handleChange}
                    isInvalid={Boolean(
                      touched.phone_number && errors.phone_number
                    )}
                    placeholder="Should start with 255"
                  />
                  {!!touched.phone_number && (
                    <Form.Control.Feedback type="invalid">
                      {errors.phone_number}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <br></br>

            <h4>Role</h4>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="inputUsername">Select a role</Form.Label>
                  <Form.Select onChange={handleChange} name="role">
                    <option value="administrator">Administrator</option>
                    <option value="helper">Helper</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <div className="m-2">
                  <h5 className="text-danger">Note</h5>
                  <ul>
                    <li>
                      <strong>Administrator:</strong> has full control
                    </li>
                    <li>
                      <strong>Helper</strong> cant create users
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
            <Button
              type="submit"
              className="btn-success btn-lg"
              // onClick={formDataToParams}
            >
              Create User
            </Button>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default CreateUserForm;

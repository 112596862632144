import { createSlice } from "@reduxjs/toolkit";
import dashboardItems from "./../../components/sidebar/dashboardItems";
import airtimeItems from "./../../components/sidebar/airtimeItems";

const serviceSlice = createSlice({
  name: "service_slice",
  initialState: {
    value: {
      name: "Bulk SMS",
      items: "dashboardItems",
    },
  },
  reducers: {
    setCurrentService: (state, action) => {
      state.value = action.payload;
    },
  },
});

export default serviceSlice.reducer;
export function switchService(currentService) {
  return (dispatch) => {
    console.log("Switching service");
    switch (currentService) {
      case "bulk-sms":
        window.localStorage.setItem("sema-current-service", "bulk-sms");
        dispatch(
          serviceSlice.actions.setCurrentService({
            name: "Bulk SMS",
            items: "dashboardItems",
          })
        );
        break;
      case "airtime":
        window.localStorage.setItem("sema-current-service", "airtime");
        dispatch(
          serviceSlice.actions.setCurrentService({
            name: "Airtime",
            items: "airtimeItems",
          })
        );
        break;
      default:
        break;
    }
  };
}

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const smsTablesApi = createApi({
  reducerPath: "smsTablesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    mode: "cors",
    prepareHeaders: (headers, { getState }) => {
      const token = window.localStorage.getItem("accessToken");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["AnniversarySmsList"],
  endpoints: (builder) => ({
    getAllAnniversarySmsList: builder.query({
      query: (account_id) => `anniversary-sms/${account_id}`,
      transformResponse: (response, meta, arg) => response.status,
      providesTags: ["AnniversarySmsList"],
    }),
    addAnniversarySmsList: builder.mutation({
      query: (anniversarySms) => ({
        url: "anniversary-sms",
        method: "POST",
        body: anniversarySms,
      }),
      transformErrorResponse: (response, meta, arg) => response.data,
      invalidatesTags: ["AnniversarySmsList"],
    }),
  }),
});

export const {
  useGetAllAnniversarySmsListQuery,
  useAddAnniversarySmsListMutation,
} = smsTablesApi;

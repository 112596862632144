import React, { useContext, useEffect, useState } from "react";
import { Card, Table, Badge, Button, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import useAuth from "../../../../hooks/useAuth";
import { useTable } from "react-table";
import {
  useGetAccountUsersQuery,
  useDeleteAccountUserMutation,
} from "../../../../redux/slices/accounts";
import axios from "axios";
import NotyfContext from "../../../../contexts/NotyfContext";
import { Delete, Edit, Trash2 } from "react-feather";

const DeleteAlertModal = ({ user, setShowDeleteModal }) => {
  const notyf = useContext(NotyfContext);
  const [deleteAccountUser] = useDeleteAccountUserMutation();
  const handleDelete = async () => {
    const res = await deleteAccountUser(user.id);
    setShowDeleteModal(false);
    console.log("Deleted user: ", res);
    notyf.success("Succesfully deleted");
  };
  return (
    <Modal show={true} centered>
      <Modal.Header>Delete user</Modal.Header>
      <Modal.Body>
        Are you sure you want to delete user : <strong>{user.email} </strong>?
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex flex-row justify-content-between">
          <Button
            onClick={() => setShowDeleteModal(false)}
            variant="outline-secondary"
          >
            Cancel
          </Button>
          <Button onClick={handleDelete} variant="danger">
            Delete
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
const UserList = () => {
  const [currentUser, setCurrentUser] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const editUser = (user) => {
    setCurrentUser(user);
  };
  const deleteUser = (user) => {
    setCurrentUser(user);
    setShowDeleteModal(true);
  };
  const { data = [], error, isLoading } = useGetAccountUsersQuery();
  if (!isLoading) {
  }
  const [users, setUsers] = useState([]);
  const { user } = useAuth();
  // const { data = [], error, isLoading } = useGetAccountUsersQuery();
  useEffect(() => {
    async function getUsers() {
      try {
        const userss = await axios.get("list_users", {
          account_id: user.account_id,
        });
      } catch (e) {}
    }
    getUsers();
  });

  // const datas = React.useMemo(() => users, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "avatar",
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value, row }) => {
          return (
            <p>{`${row.original.first_name} ${row.original.last_name}`}</p>
          );
        },
      },
      {
        Header: "Role",
        accessor: "role",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          switch (value) {
            case "active":
              return <Badge bg="success">Active</Badge>;
            case "Inactive":
              return <Badge bg="warning">Inactive</Badge>;
            default:
              return <Badge bg="light">Unkown</Badge>;
          }
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ value, row }) => (
          <div className="d-flex flex-row">
            <Edit
              className="m-3"
              size={16}
              onClick={() => editUser(row.original)}
              style={{ cursor: "pointer" }}
            />
            <Trash2
              className="m-3"
              size={16}
              color="#d34d45"
              style={{ cursor: "pointer" }}
              onClick={() => deleteUser(row.original)}
            />
          </div>
        ),
      },
    ],
    []
  );

  const userTable = useTable({ columns, data });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    userTable;

  return (
    <Card>
      {showDeleteModal ? (
        <DeleteAlertModal
          user={currentUser}
          setShowDeleteModal={setShowDeleteModal}
        />
      ) : null}

      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          All Users
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table responsive {...getTableProps()} className="mb-0">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};
export default UserList;

import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Modal,
  Alert,
  Spinner,
  CloseButton,
} from "react-bootstrap";
import Timezones from "../../../utils/timezones";
import { useSelector, RootStateOrAny } from "react-redux";
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import * as Yup from "yup";
import axios from "axios";
import NotyfContext from "../../../contexts/NotyfContext";
import * as XLSX from "xlsx";
import { useContext } from "react";

const UploadFileSMS = () => {
  const user = useSelector((state) => state.user.value);
  const notyf = useContext(NotyfContext);

  // const sender_ids = useSelector((state) => state.sender_ids.values);
  const sender_ids = [];

  const [colsList, setColsList] = useState([]);

  const checkValidity = (value, isRequired) => {
    let isValid = true;
    if (isRequired) {
      isValid = value.trim() === "";
      return isValid;
    }
    return !isValid;
  };
  let i = 0;

  const readExcelFile = (file, setFieldValue) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (err) => {
        reject(err);
      };
    });

    promise.then((data) => {
      const cols = Object.keys(data[0]);
      setFieldValue("colsList", cols);
      setColsList(cols);
    });
  };

  return (
    <React.Fragment>
      <Helmet title="Contact List SMS" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">File Upload</h1>

        <Card>
          <Card.Header>
            <Card.Title tag="h5">Upload File</Card.Title>
            <h6 className="card-subtitle text-muted">
              Upload phone numbers stored in a file
            </h6>
          </Card.Header>
          <Card.Body>
            <Formik
              initialValues={{
                sms_type: "T",
                sender_id: "Sema",
                sms_encoding: "T",
                phonenumber_prefix: "",
                colsList: [],
                the_file: "",
                openModal: false,
                sms_template: "",
                csv_columns: "",
                sms_schedule: false,
                message: "",
                phone_number_column: "",
                message_column: "",
                job_name: "",
              }}
              validationSchema={Yup.object().shape({
                sms_type: Yup.string().required("Select the sms type"),
                sender_id: Yup.string().required("Select a sender id"),
                sms_encoding: Yup.string().required("Select sms encoding"),
                phonenumber_prefix: Yup.string(),
                sms_template: Yup.string(),
                csv_columns: Yup.string(),
                message: Yup.string().required("A message is required"),
                job_name: Yup.string(),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  console.log("File upload", values);
                  console.log("The file: ", values.the_file);
                  const fd = new FormData();

                  fd.append("the_file", values.the_file);
                  fd.append("sms_type", values.sms_type);
                  fd.append("sms_encoding", values.sms_encoding);
                  fd.append("sender_id", values.sender_id);
                  fd.append("phone_number_column", values.phone_number_column);
                  fd.append("message_column", values.message_column);
                  fd.append("sms_schedule", values.sms_schedule);
                  fd.append("message", values.message);
                  fd.append("phonenumber_prefix", values.phonenumber_prefix);
                  fd.append("sms_template", values.sms_template);
                  fd.append("job_name", values.job_name);
                  console.log("Form data: ", fd);

                  const response = await fetch(
                    `${process.env.REACT_APP_API_URL}uploadfile-sms`,
                    {
                      method: "POST",
                      body: fd,
                      mode: "cors",
                      credentials: "omit",
                      headers: {
                        Authorization: `Bearer ${window.localStorage.getItem(
                          "accessToken"
                        )}`,
                      },
                    }
                  );

                  console.log("Uploading file", response);
                  const body = await response.json();
                  if (body.success) {
                    notyf.success("Campaign Submitted Succesfully");
                  } else {
                    switch (body.ErrorCode) {
                      case 1000:
                        notyf.success("Campaign Submitted Succesfully");
                        break;
                      case 1002:
                        notyf.error("File not found. Check the file to upload");
                        break;
                      case 1005:
                        notyf.error("Invalid API Key");
                        break;
                      case 1007:
                        notyf.error("APIs not Enabled for this Account");
                        break;
                      default:
                        setStatus({ success: false });
                        setSubmitting(false);
                        setErrors({ submit: "Something went wrong" });
                        notyf.error("Something went wrong");
                        break;
                    }
                  }

                  console.log("File uploaddd sms: ", body);
                } catch (error) {
                  console.log("errs", error);

                  const message = error.message || "Something went wrong";

                  setStatus({ success: false });
                  setErrors({
                    submit: "Something went wrong. Please try again later",
                  });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                touched,
                values,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {errors.submit && (
                    <Alert className="my-3" variant="danger">
                      <div className="alert-message">{errors.submit}</div>
                    </Alert>
                  )}
                  <Row>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>SMS Type</Form.Label>
                        <Form.Select
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="sms_type"
                        >
                          <option value="T">Transactional</option>
                          <option value="P">Promotional</option>
                        </Form.Select>
                        {!!touched.sms_type && (
                          <Form.Control.Feedback type="invalid">
                            {errors.sms_type}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Select Sender Id</Form.Label>
                        <Form.Select
                          name="sender_id"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option />
                          {sender_ids.map((sender_id) => (
                            <option
                              key={sender_id.sender_id}
                              value={sender_id.sender_id}
                            >
                              {sender_id.sender_id}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Check
                          type="checkbox"
                          id="checkbox"
                          label="Open Sender ID"
                        />
                        {!!touched.sender_id && (
                          <Form.Control.Feedback type="invalid">
                            {errors.sender_id}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Select SMS Encoding</Form.Label>
                        <Form.Select
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="sms_encoding"
                        >
                          <option value="T">
                            Text (Used for only English)
                          </option>
                          <option value="U">
                            Unicode (Used for all languages
                          </option>
                          <option value="FS">
                            Flash SMS (Used only with English
                          </option>
                          <option value="UFS">
                            Unicode Flash SMS (Use for all languages)
                          </option>
                        </Form.Select>
                        {!!touched.sms_encoding && (
                          <Form.Control.Feedback type="invalid">
                            {errors.sms_encoding}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Enter Phone number prefix (optional)
                        </Form.Label>
                        <Form.Control
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          name="phonenumber_prefix"
                          placeholder="Eg: 255"
                        />
                        {!!touched.phonenumber_prefix && (
                          <Form.Control.Feedback type="invalid">
                            {errors.phonenumber_prefix}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Upload Excel/ CSV File</Form.Label>
                        <Form.Control
                          name="the_file"
                          onChange={(event) => {
                            setFieldValue(
                              "the_file",
                              event.currentTarget.files[0]
                            );
                            setFieldValue("openModal", true);
                            readExcelFile(
                              event.currentTarget.files[0],
                              setFieldValue
                            );
                          }}
                          type="file"
                        />
                        {values.phone_number_column === "" ? null : (
                          <Row className="mt-2">
                            <Col md={9}>
                              <h3>{`Phonenumber column: ${values.phone_number_column}`}</h3>
                            </Col>
                            <Col>
                              <Button
                                variant="link"
                                onClick={(e) =>
                                  setFieldValue("openModal", true)
                                }
                              >
                                Change column
                              </Button>
                            </Col>
                          </Row>
                        )}
                        <Modal show={values.openModal} size="xl" centered>
                          <Modal.Header>
                            <h1>Select the column containing phone numbers</h1>
                            <CloseButton
                              onClick={(e) => setFieldValue("openModal", false)}
                            />
                          </Modal.Header>
                          <Modal.Body>
                            <Form.Group className="mb-3">
                              <Form.Label>Select column</Form.Label>
                              <Form.Select
                                onChange={(e) => {
                                  setFieldValue(
                                    "phone_number_column",
                                    e.target.value
                                  );
                                  setFieldValue("openModal", false);
                                }}
                                onBlur={handleBlur}
                                type="text"
                                name="phone_number_column"
                              >
                                <option></option>
                                {values.colsList.map((column) => {
                                  return (
                                    <option
                                      defaultChecked={
                                        values.phone_number_column === column
                                          ? true
                                          : false
                                      }
                                      key={column}
                                      value={column}
                                    >
                                      {column}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                              {!!touched.phone_number_column && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.phone_number_column}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </Modal.Body>
                        </Modal>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Select Template</Form.Label>
                        <Form.Select
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="sms_template"
                        >
                          <option />
                          <option>...</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Insert Excel/ CSV columns</Form.Label>
                        <Form.Select
                          onBlur={handleBlur}
                          value={values.message}
                          onChange={(e) => {
                            setFieldValue(
                              "message",
                              `${values.message}${e.target.value}`
                            );
                          }}
                          name="smsExcelCsvColumns"
                        >
                          <option />
                          {values.colsList.map((col) => (
                            <option key={"val" + col} value={`#${col}`}>
                              {col}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-3">
                    <Form.Label>Enter Message Text</Form.Label>
                    <Row>
                      <Col md={3}>
                        <Form.Check
                          type="checkbox"
                          id="smsRTL"
                          label="Enable RTL Format"
                        />
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-2">
                          <Form.Select
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="smsExcelCsvColumns"
                          >
                            <option>Select Campaign/ Shortlink</option>
                            <option>...</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Select
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            name="message_column"
                          >
                            <option value="">Select Message Column</option>
                            {values.colsList.map((column) => {
                              return (
                                <option
                                  defaultChecked={
                                    values.message_column === column
                                      ? true
                                      : false
                                  }
                                  key={column}
                                  value={column}
                                >
                                  {column}
                                </option>
                              );
                            })}
                          </Form.Select>
                          {!!touched.message_column && (
                            <Form.Control.Feedback type="invalid">
                              {errors.message_column}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.message}
                      as="textarea"
                      name="message"
                      placeholder="Enter Message Text"
                    />
                    {!!touched.message && (
                      <Form.Control.Feedback type="invalid">
                        {errors.message}
                      </Form.Control.Feedback>
                    )}
                    <ul className="mb-3">
                      <li>Encoding: GSM_7BIT</li>
                      <li>Length: {values.message.length}</li>
                      <li>Messages: 0</li>
                      <li>Per Message: 160</li>
                      <li>Remainig: {160 - values.message.length}</li>
                    </ul>
                  </Form.Group>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Schedule Message</Form.Label>
                        <Form.Select
                          onChange={handleChange}
                          name="sms_schedule"
                        >
                          <option value={false}>No</option>
                          <option value={true}>Yes</option>
                        </Form.Select>
                      </Form.Group>
                      {values.sms_schedule === "true" ? (
                        <Row>
                          <Col>
                            <Form.Group className="mb-3">
                              <Form.Label>Select Timezone</Form.Label>
                              <Form.Select
                                onChange={handleChange}
                                type="text"
                                name="smsType"
                              >
                                {Timezones.map((timezone) => (
                                  <option>
                                    {timezone.offset + " " + timezone.name}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group className="mb-3">
                              <Form.Label>Select Date & Time</Form.Label>
                              <Form.Control
                                onChange={handleChange}
                                type="datetime-local"
                                name=""
                                min="11/07/2021 19:30 21"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      ) : null}
                      {/* {scheduleForm} */}
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Job Name (optional)</Form.Label>
                        <Form.Control
                          // onChange={(event) =>
                          //   // inputChangedHandler(event, "smsJobName")
                          // }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="job_name"
                          placeholder="Please enter a job name"
                        />
                        {!!touched.job_name && (
                          <Form.Control.Feedback type="invalid">
                            {errors.job_name}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}
                    Send Campaign
                  </Button>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default UploadFileSMS;

import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
// import counterReducer from "./slices/counter";
import senderIDReducer from "./slices/senderids";
import userReducer from "./slices/user";
import serviceReducer from "./slices/serviceSlice";
import accountReducer from "./slices/accounts";
import { smsTablesApi } from "./slices/smsTables";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { campaignApi } from "./slices/campaigns";

//RTK QUERY
import { accountsApi } from "./slices/accounts";

export const store = configureStore({
  reducer: {
    user: userReducer,
    sender_ids: senderIDReducer,
    sema_services: serviceReducer,
    account: accountReducer,
    [smsTablesApi.reducerPath]: smsTablesApi.reducer,
    [accountsApi.reducerPath]: accountsApi.reducer,
    [campaignApi.reducerPath]: campaignApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      smsTablesApi.middleware,
      accountsApi.middleware,
      campaignApi.middleware,
    ]),
});

setupListeners(store.dispatch);
